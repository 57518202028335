<template>
  <div>
    <SubNav :title="title" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col-lg-9">
                <b-form
                  v-if="show"
                  v-loading="submittingForm"
                  @submit.prevent="onSubmit"
                  @reset.prevent="onReset"
                >
                  <div class="pt-2 pb-2">
                    <label>Name</label>
                    <div class="row">
                      <div class="col-lg-5">
                        <b-form-input
                          v-model="form.firstName"
                          placeholder="First name"
                          required
                        ></b-form-input>
                      </div>
                      <div class="col"></div>
                      <div class="col-lg-5">
                        <b-form-input
                          v-model="form.lastName"
                          placeholder="Last name"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                  </div>

                  <b-form-group class="pt-2 pb-2" label="Email">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder="Enter email address to invite"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group v-slot="{ ariaDescribedby }" label="Roles" class="user-roles">
                    <b-form-checkbox-group
                      v-model="form.selectedRoles"
                      :options="roles"
                      :aria-describedby="ariaDescribedby"
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>

                  <div class="pt-4 pb-2">
                    <b-button type="submit" class="mr-2 action-btn btn shadow" variant="primary">
                      Send Invitation
                      <b-icon icon="cursor-fill"></b-icon>
                    </b-button>
                    <b-button type="reset" class="action-btn btn shadow" variant="danger"
                      >Cancel</b-button
                    >
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/SubNav.vue";
import userResource from "../../api/userResource";

export default {
  name: "InviteUser",
  components: { SubNav },
  data() {
    return {
      title: "Invite new member",
      submittingForm: false,
      form: {
        email: "",
        firstName: "",
        lastName: "",
        selectedRoles: [],
      },
      show: true,
      roles: [
        { value: "super-admin", text: "Super Admin" },
        { value: "admin", text: "Admin" },
        { value: "staff", text: "Staff" },
        { text: "Data Viewer", value: "data-viewer" },
      ],
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.submittingForm = true;
        // eslint-disable-next-line no-unused-vars
        const response = await userResource.invite(
          this.form.email,
          this.form.firstName,
          this.form.lastName,
          this.form.selectedRoles
        );
        this.$notify({
          message: "Invite sent to ${this.form.email}",
          type: "success",
          duration: 0,
        });
      } catch (error) {
        console.error(error);
        this.$notify.error("Error processing request");
      } finally {
        this.submittingForm = false;
      }
    },
    onReset() {
      this.form = {
        email: "",
        firstNameL: "",
        lastName: "",
        selectedRoles: [],
      };
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btn {
  width: 170px;
}
.user-roles {
  max-height: 270px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
</style>
